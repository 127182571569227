









































































import {Component, Prop, Vue} from "vue-property-decorator";
import {IWork} from "@store/works/Interface";

@Component({
  name: "WorkCard"
})
export default class WorkCard extends Vue{
  @Prop(Object) readonly work!: IWork
}
